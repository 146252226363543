export enum ReferralStatusEnum {
    Created = 1,
    OnAir = 2,
    Paused = 3,
    Expired = 4,
}

export const ReferralStatus: { [key: number]: ReferralStatusEnum } = {
    1: ReferralStatusEnum.Created,
    2: ReferralStatusEnum.OnAir,
    3: ReferralStatusEnum.Paused,
    4: ReferralStatusEnum.Expired,
}

export const ReferralStatusArray = [
    {
        label: 'Created',
        value: 1,
    },
    {
        label: 'OnAir',
        value: 2,
    },
    {
        label: 'Paused',
        value: 3,
    },
    {
        label: 'Expired',
        value: 4,
    },
]
