<template>
    <CContainer fluid>
        <CToaster position='top-right' :autohide='1500'>
            <template v-for='(toast, index) in toasts'>
                <CToast :key="'toast' + toast.text + index" :show='true'
                    :header="toast.header === '' ? null : toast.header">
                    {{ toast.text }}
                </CToast>
            </template>
        </CToaster>
        <CRow>
            <CCol class='col-12'>
                <CCard>
                    <CRow class='mt-3 ml-3 mr-3 justify-content-between'>
                        <CCol class='col-12 col-xl-3 col-md-6 mb-1 justify-content-end'>
                            <CButton @click='newReferralModal = true' block color='success'>Yeni referans ekle</CButton>

                        </CCol>
                    </CRow>
                    <CCardHeader>
                        <strong>Sevk listesi</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol class='col-6'>
                                <CInput label='Arama: ' :horizontal="{ label: 'w-auto', input: 'w-100' }"
                                    v-debounce:600ms='changeFilterValue' />
                            </CCol>
                        </CRow>
                        <CDataTable class='mb-0 table-outline' hover :items='tableItems' :fields='tableFields'
                            head-color='light' :loading='isListLoading' :items-per-page='itemsPerPage'>
                            <template #no-items-view>
                                <CRow>
                                    <CCol class='col-12 font-3xl text-center'>
                                        <CIcon name='cil-x' />
                                        Veri Bulunamadı
                                    </CCol>
                                </CRow>
                            </template>
                            <td slot='id' slot-scope='{item}'>
                                <div>{{ item.id }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot='code' slot-scope='{item}'>
                                <div>{{ item.code }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot='title' slot-scope='{item}'>
                                <div>{{ item.title }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot='description' slot-scope='{item}'>
                                <div>{{ item.description }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot="discount" slot-scope='{item}'>
                                <div>{{ item.discount }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot="startDate" slot-scope='{item}'>
                                <div>{{ item.startDate | formatDate }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot="endDate" slot-scope='{item}'>
                                <div>{{ item.endDate | formatDate }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot="status" slot-scope='{item}' width="125">
                                <!-- <div>{{ item.statusName }}</div>
                                <div class='small text-muted'>
                                </div> -->
                                <CSelect label="" :value.sync="item.status"
                                    @update:value='updateStatus($event, item.id)' vertical :options="statusOptions"
                                    placeholder="" />
                            </td>

                            <td slot="studentLimit" slot-scope='{item}'>
                                <div>{{ item.studentLimit }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot="availedLimit" slot-scope='{item}'>
                                <div>{{ item.availedLimit }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot="students" slot-scope='{item}'>
                                <router-link :to="'/referral/' + item.id" tag='button' role='button' block
                                    class='btn btn-success mb-1 ml-1'>
                                    <CIcon name='cil-user' />
                                </router-link>
                            </td>

                            <td slot='manage' slot-scope={item}>
                                <CButton @click='showEditModal(item.id)' color='success' class='mr-2 mb-1'>
                                    <CIcon name='cil-pencil' />
                                </CButton>
                                <CButton @click='showDeleteAlert(item.id)' class='mb-1' color='danger'>
                                    <CIcon name='cil-x' />
                                </CButton>
                            </td>
                        </CDataTable>
                        <CPagination v-if='totalPages !== 0' :pages='totalPages' :active-page='currentPage'
                            :activePage.sync='currentPage' align='center' class='mt-3'>
                        </CPagination>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>

        <!-- New Referral Modal -->

        <CModal title='Yeni referans ekle' color='Info' :show.sync='newReferralModal'>
            <CContainer fluid>
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='Başlık' type='text' description='ex. Başlık' label='Başlık'
                            v-model="title">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <CInput placeholder='tanım' type='text' description='ex. tanım' label='Tanım'
                            v-model="description">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <!-- <CInput placeholder='indirim' type='text' description='ex. indirim' label='Indirim (%)'
                            v-model="discount">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput> -->
                        <CSelect label="Indirim (%)" :value.sync="selectedDiscount" vertical :options="discountOptions" placeholder="" />
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <CInput placeholder='Başlangıç ​​tarihi' type='date' description='ex. Başlangıç ​​tarihi'
                            label='Başlangıç ​​tarihi' v-model="startDate" :is-valid="dateValidator">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <CInput placeholder='Bitiş tarihi' type='date' description='ex. Bitiş tarihi'
                            label='Bitiş tarihi' v-model="endDate" :is-valid="dateValidator">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <CInput placeholder='öğrenci sınırı' type='text' description='ex. öğrenci sınırı'
                            label='öğrenci sınırı' v-model="studentLimit">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
            </CContainer>
            <template v-slot:footer>
                <CContainer fluid>
                    <CRow class='d-flex justify-content-between'>
                        <CCol class='col-6'>
                            <CButton @click='newReferralModal = false' block color='dark'>Vazgeç</CButton>
                        </CCol>
                        <CCol class='col-6'>
                            <CButton block color='info' @click='addReferral' :loading='isLoading'>Değişiklikleri Kaydet
                            </CButton>
                        </CCol>
                    </CRow>
                </CContainer>
            </template>
        </CModal>

        <!-- Edit Referral Modal -->

        <CModal title='Yeni referans ekle' color='Info' :show.sync='editReferralModal'>
            <CContainer fluid>
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='Başlık' type='text' description='ex. Başlık' label='Başlık'
                            v-model="title">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <CInput placeholder='tanım' type='text' description='ex. tanım' label='Tanım'
                            v-model="description">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <!-- <CInput placeholder='indirim' type='text' description='ex. indirim' label='Indirim (%)'
                            v-model="discount">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput> -->
                        <CSelect label="Indirim (%)" :value.sync="selectedDiscount" vertical :options="discountOptions" placeholder="" />
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <CInput placeholder='Başlangıç ​​tarihi' type='date' description='ex. Başlangıç ​​tarihi'
                            label='Başlangıç ​​tarihi' v-model="startDate">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <CInput placeholder='Bitiş tarihi' type='date' description='ex. Bitiş tarihi'
                            label='Bitiş tarihi' v-model="endDate">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <CInput placeholder='öğrenci sınırı' type='text' description='ex. öğrenci sınırı'
                            label='öğrenci sınırı' v-model="studentLimit">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
            </CContainer>
            <template v-slot:footer>
                <CContainer fluid>
                    <CRow class='d-flex justify-content-between'>
                        <CCol class='col-6'>
                            <CButton @click='editReferralModal = false' block color='dark'>Vazgeç</CButton>
                        </CCol>
                        <CCol class='col-6'>
                            <CButton block color='info' @click='updateReferral' :loading='isLoading'>Değişiklikleri
                                Kaydet</CButton>
                        </CCol>
                    </CRow>
                </CContainer>
            </template>
        </CModal>
    </CContainer>
</template>

<script>
import { ReferralAdministration } from '@/services/api-service'
import { ReferralStatusEnum, ReferralStatusArray } from "./Referral.types";
import VueLoadingButton from 'vue-loading-button'
import moment from 'moment'

export default {
    name: 'Referral',
    Vcomponents: {
        VueLoadingButton,
    },
    mounted() {
        this.loadReferralList()
    },
    watch: {
    },
    data() {
        return {
            filterValue: '',
            currentPage: 1,
            itemsPerPage: 10,
            totalPages: 1,
            isStyled: true,
            currentItem: '',
            statusOptions: ReferralStatusArray,
            id: '',
            title: '',
            description: '',
            discount: 0,
            startDate: '',
            endDate: '',
            studentLimit: 0,
            status: 0,
            discountOptions: [0, 12, 26, 35, 42],
            selectedDiscount: 0,

            isListLoading: false,
            isLoading: false,
            editReferralModal: false,
            newReferralModal: false,
            toasts: [],
            tableItems: [],
            tableFields: [
                { key: 'id', label: 'ID' },
                { key: 'code', label: 'Kod' },
                { key: 'title', label: 'Başlık' },
                { key: 'description', label: 'Tanım' },
                { key: 'discount', label: 'Indirim' },
                { key: 'startDate', label: 'Başlangıç ​​tarihi' },
                { key: 'endDate', label: 'Bitiş tarihi' },
                { key: 'status', label: 'Durum' },
                { key: 'studentLimit', label: 'öğrenci sınırı' },
                { key: 'availedLimit', label: 'Yararlı sınır' },
                { key: 'students', label: 'öğrenciler' },
                { key: 'manage', label: 'Yönet' },
            ],
        }
    },
    computed: {

    },
    methods: {
        dateValidator() {
            if (this.startDate && this.endDate) {
                if (!(moment(this.startDate, 'YYYY-MM-DD').isSameOrBefore(moment(this.endDate, 'YYYY-MM-DD')))) {
                    this.endDate = ''
                    this.$toast.error('Bitiş tarihi, başlangıç ​​tarihinden büyük olmalıdır.')
                }
            }
        },
        changeFilterValue(newValue) {
            this.filterValue = newValue
        },
        showEditModal(id) {
            this.currentItem = this.tableItems.find(x => x.id === id)
            this.id = this.currentItem?.id
            this.title = this.currentItem?.title
            this.description = this.currentItem?.description
            this.discount = this.currentItem?.discount
            this.selectedDiscount = this.currentItem?.discount
            this.startDate = moment(String(this.currentItem?.startDate)).format('YYYY-MM-DD')
            this.endDate = moment(String(this.currentItem?.endDate)).format('YYYY-MM-DD')
            this.studentLimit = this.currentItem?.studentLimit
            this.status = this.currentItem?.status

            console.log(this.currentItem)
            this.editReferralModal = !this.editReferralModal
        },
        showDeleteAlert(id) {
            this.$swal({
                title: 'Emin misiniz?',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await ReferralAdministration.deleteReferral(id)
                    this.tableItems = this.tableItems.filter(r => r.id !== id)

                    this.tableItems = this.tableItems.reverse()

                    this.$swal.fire(
                        'Silindi!',
                        'İşlem Başarıyla Gerçekleştirildi!',
                        'success',
                    )
                }
            })
        },
        showAlert() {
            this.$swal({
                title: 'Emin misiniz?',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal.fire(
                        'Silindi!',
                        'İşlem Başarıyla Gerçekleştirildi!',
                        'success',
                    )
                }
            })
        },
        showToast: function (text = 'İşlem Başarıyla Onaylandı', header = 'Bilgi') {
            this.toasts.push({ text, header })
        },
        deleteToast: function (text = 'İşlem Başarıyla Onaylandı', header = 'Bilgi') {
            this.toasts.push({ text, header })
        },
        async addReferral() {
            if (this.title && this.description && this.selectedDiscount && this.startDate && this.endDate && this.studentLimit) {

                this.isLoading = true
                try {
                    const result = await ReferralAdministration.createReferral({
                        title: this.title,
                        description: this.description,
                        discount: this.selectedDiscount,
                        startDate: new Date(this.startDate),
                        endDate: new Date(this.endDate),
                        studentLimit: this.studentLimit,
                    })
                    console.log(result);
                    console.log(result.data.id);
                    console.log(result.data.code);

                    this.loadReferralList();
                    this.$toast.success('Yeni Referans Başarıyla Eklendi!')
                    this.title = '';
                    this.description = '';
                    this.discount = 0;
                    this.startDate = '';
                    this.endDate = '';
                    this.studentLimit = 0;
                    this.isLoading = false;
                    this.newReferralModal = false;
                }
                catch (e) {
                    throw new e
                }
            }
        },
        async updateStatus(ev, refId) {
            this.isListLoading = true;
            await ReferralAdministration.updateStatusReferral({
                id: refId,
                status: ev
            })
            this.$toast.success('Durum Başarıyla değiştirildi!')
            this.isListLoading = false;
        },
        async updateReferral() {
            if (this.title && this.description && this.selectedDiscount && this.startDate && this.endDate && this.studentLimit) {
                this.isLoading = true
                try {
                    this.isListLoading = true;

                    if (this.status) {
                        const res = await ReferralAdministration.updateStatusReferral({
                            id: this.id,
                            status: this.status
                        })
                        console.log(res)
                    }

                    const result = await ReferralAdministration.updateReferral({
                        id: this.id,
                        title: this.title,
                        description: this.description,
                        discount: this.selectedDiscount,
                        startDate: new Date(this.startDate),
                        endDate: new Date(this.endDate),
                        studentLimit: this.studentLimit,
                    })
                    console.log(result);

                    this.loadReferralList();
                    this.$toast.success('Yeni Referans Başarıyla Eklendi!')
                    this.title = '';
                    this.description = '';
                    this.discount = 0;
                    this.startDate = '';
                    this.endDate = '';
                    this.studentLimit = 0;
                    this.isLoading = false
                    this.editReferralModal = false;
                }
                catch (e) {
                    throw new e
                }
            }
        },
        async loadReferralList() {
            this.isListLoading = true;
            const result = await ReferralAdministration.listReferral(this.currentPage, this.itemsPerPage, this.filterValue)
            console.log(result);

            this.tableItems = result.data.referralList

            this.tableItems.map((item) => {
                item.statusName = ReferralStatusEnum[item.status]
            })

            this.tableItems = this.tableItems.reverse()

            console.log(this.tableItems)

            this.totalPages = result.data.pagination.totalPages
            this.currentPage = result.data.pagination.currentPage
            this.itemsPerPage = result.data.pagination.recordsPerPage

            this.isListLoading = false;
        },
    },
}
</script>
